import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";
import "./header.css";

function Header({ acao }) {
  const navigate = useNavigate();

  const scrollToFrequentlyQuestions = () => {
    scroller.scrollTo("signatureAgainContainer", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const scrollToOfferContainer = () => {
    scroller.scrollTo("offerTitle", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  let dropDownRef = useRef();

  const [isActive, setIsActive] = useState(false);
  
  const handleOpenDropDown = () => setIsActive(!isActive);

  useEffect(() => {


    if(!isFrequentlyQuestionsPage() && !isCongratulationsPage() && !isDataChangePage()) {
      let handler = (e) => {
        if(!dropDownRef.current.contains(e.target)){
            setIsActive(false);
        }

    };
  
    document.addEventListener("mousedown", handler);
  
    return() => {
        document.removeEventListener("mousedown", handler);
    }

    }



  },[])


  // Função para verificar se a página atual é "congratulations"
  const isHomePage = () => {
    return location.pathname === "/";
  };
  const isFrequentlyQuestionsPage = () => {
    return location.pathname === "/register";
  };
  const isCongratulationsPage = () => {
    return location.pathname === "/congratulations";
  };

  const isDataChangePage = () => {
    return location.pathname === "/dataChange";
  };

  const isResetPasswordPage = () => {
    return location.pathname === "/resetPassword";
  };

  return (
    <header className={acao ? "colorShow" : "colorNotShow"}>
      <div className="headerLogoContainer">
        <a href="/">
          <img src={require("../../assets/logo_sexprive.png")} alt="Logo"></img>
        </a>
      </div>

      <div className="headerActionOptionsContainer">
        {/* Adicione a condição para renderizar ou não o botão "ASSINE AGORA" */}

        {!isFrequentlyQuestionsPage() && !isCongratulationsPage() && !isDataChangePage() &&  !isResetPasswordPage()  &&(
          <button
            className="actionLink"
            onClick={() => {
              scrollToFrequentlyQuestions();
            }}
          >
            Dúvidas frequentes
          </button>
        )}
        {!isFrequentlyQuestionsPage() && !isCongratulationsPage() && !isDataChangePage() &&  !isResetPasswordPage()  && (
          <button
            className="actionLink"
            onClick={() => {
              navigate("/dataChange");
            }}
          >
            Central do assinante
          </button>
        )}
        {!isFrequentlyQuestionsPage() && !isCongratulationsPage() && !isDataChangePage() &&  !isResetPasswordPage()  &&(
          <button
            className="actionButton"
            onClick={() => {
              scrollToOfferContainer();
            }}
          >
            VER MAIS
          </button>
        )}

        {!isFrequentlyQuestionsPage() && !isCongratulationsPage() && !isDataChangePage() && !isResetPasswordPage()  &&(
          <a className="actionLink2">
            <button className="actionButton2" onClick={() => {
                window.location.href = "https://sexpriveplay.com.br/login";

            }}>
              ENTRAR
            </button>
          </a>
        )}


      </div>

        {!isFrequentlyQuestionsPage() &&!isCongratulationsPage() && !isDataChangePage() && (
          <div ref={dropDownRef} className="headerActionOptionsContainerMobile">
            <div className="configOpenOptions">
              <button onClick={handleOpenDropDown}><img src={require("../../assets/menu.png")} alt="Menu Icon"></img></button>
            </div>

            <div className={`containerStyle ${isActive ? "active" : "inactive"}`}>
              <ul>
                <li onClick={() => {
                    window.location.href = "https://sexpriveplay.com.br/login";
                }}>Entrar</li>
                <li onClick={() => {
                  scrollToOfferContainer();
                }}>Ver mais</li>

                <li onClick={() => {
                  navigate("/dataChange");
                }}>Central do Assinante</li>

                <li onClick={() => {
                  scrollToFrequentlyQuestions();
                }}>Dúvidas Frequentes</li>

              </ul>
            </div>
          </div>
        )}





    </header>
  );
}

Header.propTypes = {
  acao: PropTypes.bool.isRequired,
};

export default Header;