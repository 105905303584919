import React, { useEffect, useState, useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import formatDuration from "../../utils/formatDuration";
import formatDate from "../../utils/formatStart";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./index.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

import { useNavigate } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";

function getRandomIntInclusive() {
  const vlr = Math.floor(Math.random() * 26) + 1;

  return vlr;
}
function getRandomIntInclusiveToVideo() {
  const vlr = Math.floor(Math.random() * 2) + 1;

  return vlr;
}

const scrollToOfferContainer = () => {
  scroller.scrollTo("offerTitle", {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
  });
};

export default function SliderInitial(result) {
  const navigate = useNavigate();

  useEffect(function () {}, []);

  return (
    <>
      <Swiper
        direction={"horizontal"}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="slider"
      >

        {result.result.data.response.filter(e => e.type !== "channels").map((item, idx) => {
          const flNumber = Math.floor(Math.random() * item.data.length - 1) + 1;
          return (
            <SwiperSlide className="sliderCard" key={idx}>
              {item.type === "images" ? (
                <>
                  <video
                    autoPlay
                    muted
                    loop
                    poster={item.data[0].image_widescreen}
                  >
                    <source src={item.data[0].video} type="video/mp4" />
                  </video>

                  <div className="sliderCardContent">
                    <div className="sliderCardTitleVideo">
                      <h1 className="sliderCardTitleVideo">
                        Desfrute do melhor conteúdo para adultos no Canal
                        SEXPRIVÉ!
                      </h1>
                    </div>

                    <div className="sliderCardButtonVideo">
                      <button
                        onClick={() => {
                          scrollToOfferContainer();
                          window.scrollTo(0, 0);
                        }}
                      >
                        VEJA MAIS!
                      </button>
                    </div>
                  </div>

                  <div className="shadowImageBgd"></div>
                  <div className="bottomShadow"></div>
                </>
              ) : (
                <>
                  <img
                    src={require(
                      "../../assets/img_slide" +
                        getRandomIntInclusive() +
                        ".png"
                    )}
                  ></img>

                  <div className="sliderCardContent">
                    <div className="sliderCardTitle">
                      {item.title === "Top Canais" ? (
                        <h1>
                          Assista no Canal SEXPRIVÉ: {item.data[flNumber].title}
                        </h1>
                      ) : (
                        <h1>Para você: O melhor de {item.title}</h1>
                      )}
                    </div>

                    <div className="sliderCardDescription">
                      {item.title === "Top Canais" ? (
                        <h3>{item.data[flNumber].description}</h3>
                      ) : (
                        <h3>
                          {item.data[flNumber].title}:{" "}
                          {item.data[flNumber].description}
                        </h3>
                      )}
                    </div>

                    <div className="sliderCardDurationAndStart">
                      {item.title === "Top Canais" ? (
                        <div className="sliderCardStart">
                          <h3>{formatDate(item.data[flNumber].start)}</h3>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="sliderCardDuration">
                        <img src={require("../../assets/clock.png")}></img>
                        <h3>{formatDuration(item.data[flNumber].duration)}M</h3>
                      </div>
                    </div>

                    <div className="sliderCardButton">
                      <button
                        onClick={() => {
                          scrollToOfferContainer();
                          window.scrollTo(0, 0);
                        }}
                      >
                        VEJA MAIS!
                      </button>
                    </div>
                  </div>

                  <div className="shadowImageBgd"></div>
                  <div className="bottomShadow"></div>
                </>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
