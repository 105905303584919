import React from "react";
import "./footer.css";

function Footer() {
  return (
    <div className="footerContainer">
      <div className="footerContent">
        
        <div className="footerLogo">
          <a href="/">
            <img src={require("../../assets/logo_sexprive.png")}></img>
          </a>
        </div>

        <div className="contentDivisionFoooter">
      <div className="footerList">
          <ul>
            <a href="/termos-de-uso">
              <li>Termos e condições de uso</li>
            </a>
            <a href="/politica-de-privacidade">
              <li>Política de Privacidade</li>
            </a>
          </ul>
      </div>

        <div className="footerImgSocials">
          <div className="footerSocialsTitle">
            <p>
              <strong>Sigam nossas redes sociais!</strong>
            </p>
          </div>

          <div className="footerSocials">
            <a
              href="https://www.youtube.com/@sexpriveclub"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../../assets/youtube.png")}></img>
            </a>
            <a
              href="https://www.instagram.com/canalpriveoficial/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../../assets/instagram.png")}></img>
            </a>
            <a
              href="https://www.tiktok.com/@canalsexprive"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../../assets/tik-tok.png")}></img>
            </a>
            <a
              href="https://twitter.com/sexpriveoficial"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../../assets/twitter.png")}></img>
            </a>
          </div>
        </div>
        </div>
      </div>

      <div className="footerCopyright">
        {/* <p className="footerParagraph">
          Dúvidas? Contate-nos no email: <strong>contato@sexpriveplay.com.br</strong>
        </p> */}
        <p>
          Todos os direitos reservados © 2023, <strong>SEXPRIVÉ</strong>{" "}
        </p>
      </div>
    </div>
  );
}

export default Footer;